/* Kapsayıcı alan */
.logo-slider {
  background-color: #181818; /* Arka plan rengi */
  padding: 30px 0;
  overflow: hidden; /* Taşmayı engelle */
  position: relative;
}

.slider {
  display: flex;
  width: max-content; /* İçeriği kadar genişlesin */
  animation: scroll-left 50s linear infinite; /* Sonsuz döngüyle kaydırma */
}

.logo-card {
  flex: 0 0 auto; /* Kart genişliği sabit */
  width: 150px;
  height: 150px;
  margin: 0 10px; /* Kartlar arasındaki boşluk */
  background: #fff; /* Kart arka planı */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Gölge efekti */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo-card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Logonun orijinal boyutunu koru */
}

/* Animasyon: Sonsuz kaydırma sağdan sola */
@keyframes scroll-left {
  0% {
    transform: translateX(0%); /* Başlangıçta sağda */
  }
  100% {
    transform: translateX(-100%); /* Bitiş noktası sola doğru */
  }
}

/* Responsive Tasarım */
@media (max-width: 768px) {
  .logo-card {
    width: 120px;
    height: 120px;
  }

  .slider {
    animation-duration: 45s; /* Küçük ekranlarda animasyon daha hızlı başlasın */
  }

  /* Animasyon başlangıcını %20 olarak değiştir */
  .slider {
    animation: scroll-left 50s linear infinite;
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(20%); /* Başlangıçta %20 */
    }
    100% {
      transform: translateX(-100%); /* Bitiş noktası sola doğru */
    }
  }
}

@media (max-width: 576px) {
  .logo-card {
    width: 100px;
    height: 100px;
  }

  .slider {
    animation-duration: 45s; /* Daha da hızlı kaydırma */
  }

  /* Animasyon başlangıcını %20 olarak değiştir */
  .slider {
    animation: scroll-left 45s linear infinite;
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(20%); /* Başlangıçta %20 */
    }
    100% {
      transform: translateX(-100%); /* Bitiş noktası sola doğru */
    }
  }
}
