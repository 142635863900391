.faq-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 50px;
    max-width: 1200px;
    margin: auto;
  }
  
  .faq-content,
  .faq-image {
    flex: 1;
    min-width: 300px;
  }
  
  .faq-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Başlıkları merkeze hizalar */
  }
  
  .faq-content h3,
  .faq-content h1 {
    text-align: center; /* S.S.S ve Sıkça Sorulan Sorular ortalanır */
  }
  
  .faq-content h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }
  
  .faq-content h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .faq-list {
    list-style: none;
    padding: 0;
    width: 100%; /* Soru listesi tam genişlikte olacak */
  }
  
  .faq-item {
    border-bottom: 1px solid #0476D9; /* Alt çizgi kalınlığı 1px olarak değiştirildi */
    padding: 10px 0; /* Soru arasındaki boşluk azaltıldı */
    transition: background-color 0.3s ease;
  }
  
  .faq-item.active {
    background-color: transparent; /* Arkadaki mavilik kaldırıldı */
  }
  
  .faq-question {
    background: none;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #333;
    width: 100%;
    transition: color 0.3s ease;
    text-align: left; /* Sorular sola hizalanır */
    word-wrap: break-word; /* Kelimelerin satır sonunda kırılmasını sağlar */
  }
  
  .faq-question:hover {
    color: #0056b3; /* Daha koyu mavi hover efekti */
  }
  
  .faq-item.active .faq-question {
    color: #0056b3; /* Aktif durumdaki soru koyu mavi renkte kalır */
  }
  
  .faq-icon {
    font-size: 0.8rem;
    color: #777;
  }
  
  .faq-answer {
    margin-top: 5px; /* Yanıt arasındaki boşluk azaltıldı */
    font-size: 0.9rem;
    color: #555;
    line-height: 1.4; /* Satır yüksekliği biraz daha küçültüldü */
    padding-left: 10px; /* Soru ve içerik arasında padding */
    word-wrap: break-word; /* Kelimelerin satır sonunda kırılmasını sağlar */
  }
  
  .faq-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .faq-image img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive Tasarım */
  @media (max-width: 768px) {
    .faq-container {
      flex-direction: column;
      align-items: center;
    }
  
    .faq-content,
    .faq-image {
      flex: none;
      width: 100%;
    }
  
    .faq-list {
      text-align: left; /* Küçük ekranlarda sorular sola hizalanır */
    }
  
    .faq-content h3 {
      font-size: 1rem; /* Küçük ekranlarda başlık daha küçük */
    }
  
    .faq-content h1 {
      font-size: 1.5rem; /* Küçük ekranlarda başlık daha küçük */
    }
  
    .faq-question {
      font-size: 0.9rem; /* Küçük ekranlarda sorular daha küçük */
    }
  
    .faq-answer {
      font-size: 0.8rem; /* Küçük ekranlarda cevaplar daha küçük */
    }
  }
  