/* Genel sıfırlama */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Body */
body {
    font-family: 'Open Sans', sans-serif; /* Open Sans kullanıldı */
    line-height: 1.6;
}

/* Google Font Import */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Roboto:wght@400;500;700&family=Lora:wght@400;500;700&display=swap');

/* Header ve Image Container */
.header {
    position: relative;
    height: 50vh; /* Default height for larger screens */
}

.image-container {
    position: relative;
    height: 100%;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
}

/* Overlay kısmı */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 23, 23, 0.9);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px; /* Font büyüklüğü arttırıldı */
    text-align: center;
    flex-direction: column;
    padding-top: 100px; /* "Kurumsal" metnini daha aşağı kaydırdık */
}

.overlay h1 {
    font-size: 52px; /* Başlık boyutu arttırıldı */
    font-weight: 700; /* Daha güçlü font weight */
    
    font-family: 'Roboto', sans-serif; /* Başlık için Roboto fontu */
}

.overlay p {
    font-size: 18px;
   
    display: flex;
    justify-content: center;
    gap: 25px; /* Aralarındaki mesafeyi biraz daha arttırdık */
    font-family: 'Lora', serif; /* Lora fontu ile daha sofistike alt metin */
}

.overlay .link-item {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.overlay .link-item span {
    font-size: 20px;
    font-weight: normal;
    display: inline-flex;
    gap: 10px;
}

.overlay .link-item a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif; /* Linkler için Open Sans fontu */
}

/* Navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: transparent;
    width: 100%;
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.navbar .containeraaa{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
}

.navbar .logo {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}

.navbar .nav-links {
    display: flex;
    gap: 20px;
    margin-left: auto;
}

.navbar .nav-links li {
    list-style: none;
    cursor: pointer;
}

.navbar .nav-links li a {
    color: #fff;
    text-decoration: none;
    font-size: 15px; /* Link font boyutu arttırıldı */
    font-weight: 600; /* Linklere bold font ağırlığı verildi */
    font-family: 'Open Sans', sans-serif;
}

.navbar .hamburger {
    display: none;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
}

.navbar .close-menu {
    display: none;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
}

/* Menü Açıldığında Navbar */
.navbar.active .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    color: #000;
    gap: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar.active .hamburger {
    display: none;
}

.navbar.active .close-menu {
    display: block;
}

/* Hamburger Menü Mobilde Görünür */
@media (max-width: 768px) {
    .navbar .container {
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
    }

    .navbar .nav-links {
        display: none;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .navbar .hamburger {
        display: block;
    }

    .navbar.active .nav-links {
        display: flex;
        align-items: flex-start;
        font-size: 16px; /* Mobilde linkler biraz küçültüldü */
    }

    .navbar.active .hamburger {
        display: none;
    }

    .navbar.active .close-menu {
        display: block;
    }

    /* Logo ve Hamburger Icon Boyutunu Küçültme */
    .navbar .logo {
        font-size: 24px;
    }

    .navbar .hamburger {
        font-size: 28px;
    }

    .navbar .nav-links li a {
        font-size: 16px;
        color: #000;
    }

    /* Küçük ekranlarda hero bölümünün yüksekliğini 30vh'ye ayarla */
    .header {
        height: 30vh;
    }

    /* Küçük ekranlarda overlay metin boyutlarını küçült */
    .overlay h1 {
        font-size: 36px; /* Başlık boyutunu küçült */
    }

    .overlay p {
        font-size: 14px; /* Alt metni küçült */
        gap: 15px; /* Mesafeyi daralt */
    }

    .overlay .link-item span {
        font-size: 16px; /* Link item boyutunu küçült */
    }

    .overlay .link-item a {
        font-size: 16px; /* Link font boyutunu küçült */
    }

    /* Hamburger menüdeki linklerin boyutunu küçült */
    .navbar.active .nav-links li a {
        font-size: 14px; /* Hamburger menü linklerini küçült */
    }
}
.active{
    border-bottom: 1px solid cornsilk;
}
@media (min-width: 768px){
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 80px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: transparent;
        width: 100%;
    }
    
    .navbar .containeraaa {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
}