/* Genel Alan */
.custom-container {
    
    border-radius: 10px; /* Köşeleri yuvarlat */
  
    padding: 30px;
  }
  
  .custom-row {
    text-align: center; /* Satırdaki her şeyi ortala */
    margin-bottom: 20px; /* Satırlar arasında boşluk bırak */
  }
  
  .custom-title {
    font-size: 2.5rem; /* Başlık boyutu */
    font-weight: 700; /* Kalın başlık */
    color: #333; /* Daha koyu bir metin rengi */
    margin-bottom: 10px;
  }
  
  .custom-paragraph {
    font-size: 1rem;
    color: #555; /* Hafif gri bir metin */
  }
  
  /* Bilgi Kartları */
  .custom-info {
    display: flex;
    flex-direction: column; /* Dikey hizalama */
    align-items: center; /* Tüm içeriği yatayda ortala */
    justify-content: center; /* Dikeyde ortala */
    padding: 20px;
    background-color: #ffffff; /* Kartın arka planı */
    border-radius: 10px; /* Köşeleri yuvarlat */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Kart gölge efekti */
    transition: all 0.3s ease; /* Hover efekt için geçiş efekti */
    margin: 10px 0; /* Üst ve alt boşluk */
  }
  
  .custom-info:hover {
    transform: translateY(-5px); /* Hover durumunda yukarı kaldır */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Hover'da daha yoğun gölge */
  }
  
  .custom-info-text {
    font-size: 1rem; /* Metin boyutu */
    color: #333; /* Metin rengi */
    font-weight: 600; /* Hafif kalın metin */
    margin-top: 10px;
  }
  
  /* Simgeler */
  .custom-icon {
    color: #007bff; /* Mavi renkli simge */
    font-size: 2rem; /* Büyük simgeler */
  }
  
  /* Responsive Tasarım */
  @media (max-width: 768px) {
    .custom-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; /* Kartları ortala */
      gap: 20px; /* Kartlar arasında boşluk bırak */
    }
  
    .custom-info {
      width: 90%; /* Tüm kartlar aynı genişlikte */
      padding: 20px; /* İç boşluk */
      margin: 10px 0; /* Üst ve alt boşluk */
    }
  }
  
  @media (max-width: 480px) {
    .custom-info {
      width: 100%; /* Daha küçük ekranlarda tam genişlik */
      padding: 15px;
    }
  
    .custom-title {
      font-size: 1.8rem;
    }
  
    .custom-paragraph {
      font-size: 0.8rem;
    }
  }
  