/* Genel stil */
.sstinfo {
  text-align: center;
  padding: 10px 0;
}
.textMuted{
  font-size: 15px;
}
.denemeBaslık{
  text-align: start;
 
}
.itemTolinks:hover{
  cursor: pointer;
}

/* Başlık */
.titleHizmetler h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;

  letter-spacing: 1px;
}

/* Resimlerin hizalanması ve padding */
.itemColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-bottom: 30px;
  margin-left: 0px !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
  border-radius: 10px;
  position: relative; /* To position overlay */
  overflow: hidden;  /* Ensure that the overlay doesn't overflow the container */
}

/* Resim */
.itemImage {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

/* Dark overlay effect on image */
.itemImage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker black overlay with higher opacity */
  border-radius: 10px;
  z-index: 1; /* Ensure it sits on top of the image */
  transition: background-color 0.3s ease;
}

/* Hoverda resim büyüme ve overlay koyulaşması */
.itemImage:hover::before {
  background-color: rgba(0, 0, 0, 0.85); /* Darker overlay on hover */
}

/* Hover efekti for item column */
.itemColumn:hover {
  transform: translateY(-10px); /* Card lift on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Shadow for better effect */
}

/* Başlık (h4) düzenleme */
.itemColumn h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 15px;
  color: #333; /* Normal text color */
  text-align: center;
  text-transform: none;
  margin-bottom: 10px;
  position: relative;
  z-index: 2; /* Ensure title is on top of the overlay */
}

/* Disabled style for item description */
.itemDescription {
  font-size: 0.95rem;
  color: #9a9a9a;  /* Light gray color to indicate a disabled state */
  margin-bottom: 15px;
  text-align: center;
  opacity: 1;  /* Lower opacity to make it look disabled */
  cursor: not-allowed; /* Change cursor to indicate it's not interactive */
  position: relative;
  z-index: 2; /* Ensure description is on top of the overlay */
  transition: opacity 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
}

/* Hover effect for item description to restore normal state */
.itemColumn:hover .itemDescription {
  opacity: 1; /* Restore full opacity on hover */
  color: #333; /* Dark color for normal state */
}

/* Responsive düzenlemeler */
@media (max-width: 768px) {
  .titleHizmetler h2 {
    font-size: 1.75rem;
  }

  .itemImage {
    max-height: 250px; /* Adjusting the image size */
  }

  .itemColumn h4 {
    font-size: 1rem;
  }

  .itemColumn {
    margin-left: 20px; /* Add left margin on smaller screens */
    margin-right: 20px; /* Add right margin on smaller screens */
  }

  .itemDescription {
    font-size: 0.9rem;
  }

  .btnContinue {
    padding: 10px 20px; /* Adjust button padding */
  }
}

@media (max-width: 576px) {
  .titleHizmetler h2 {
    font-size: 1.25rem;
  }

  .itemImage {
    max-height: 220px; /* Adjusting image size for smaller screens */
  }

  .itemColumn h4 {
    font-size: 0.9rem;
  }

  .itemDescription {
    font-size: 0.85rem;
  }

  .btnContinue {
    padding: 8px 15px; /* Further reduce button padding */
  }
}

/* Add padding between the cards */
.row {
  margin-left: -15px;
  margin-right: -15px;
}

.itemColumn {
  padding: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

/* Flex container düzenlemeleri */
.rowaa {
  display: flex;
  flex-wrap: nowrap; /* Allow wrapping of columns */
  justify-content: center; /* Center the items */
  gap: 15px; /* Space between columns */
}

@media (max-width: 768px) {
  .rowaa {
    gap: 10px; /* Reduce gap on smaller screens */
    margin: 0 5%;
  }
}
