.sections2 {
    background-color: #181818;
}
.footerInfo {
    font-size: 20px;
    font-weight: 600;
    font-family: sans-serif;
    color: white;
    padding: 0px;
}
.liLinks li {
    list-style-type: none;
    display: block;
    color: white;
    margin-top: 0.9rem;
    cursor: pointer;
}
.liLinks1 li {
    list-style-type: none;
    display: block;

    color: white;
    margin-top: 0.9rem;
}
.liLinks li:hover {
    color: white;
    font-size: 1.1rem;
}
.liLinks {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.loglinks {
    font-size: 25px;
    cursor: pointer;
}
@media (max-width:480px){
    .col-lg-4, .col-md-6, .col-12{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
.logoSst{
    width: 60px;
    height: 60px;
    margin-top: 0px !important;
    cursor: pointer;
}