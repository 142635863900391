/* Title styling for 'Sistem Stok Sayım' */
h1.text-muted {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #6c757d;
    opacity: 0;
    animation: fadeIn 1s forwards 0.5s; /* Fade in animation */
    display: block; /* Ensure block level for h1 */
    text-align: center; /* Center the title */
    letter-spacing: 1px; /* Add spacing between letters for a more modern look */
    line-height: 1.2; /* Adjust line height for better readability */
    font-family: 'Arial', sans-serif; /* Use a modern font */
}

/* Secondary text styling (p) */
.secondary-text {
    font-size: 18px;
    font-weight: 400;
    color: #6c757d;
    opacity: 0;
    animation: fadeInParagraph 1s forwards 1.5s; /* Delay and animate paragraph */
    display: block; /* Ensure block level for p */
    text-align: center; /* Center the paragraph */
    margin-top: 10px;
    font-family: 'Arial', sans-serif; /* Use a modern font */
    line-height: 1.6; /* Increase line-height for better readability */
}

/* Keyframes for Fade-In animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Keyframes for Fade-In Up animation for paragraph */
@keyframes fadeInParagraph {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Card Styling */
.card {
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    height: 100%;
    opacity: 0;
    animation: fadeInUp 1s forwards 1.5s; /* Animate the cards */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition for hover effect */
}
.card:hover{
    opacity: 0.8;
}

.card-body {
    flex-grow: 1;
}

.card:hover {
    transform: scale(1.05); /* Slightly scale the card on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow effect on hover */
}

.icon {
    font-size: 40px;
   color: #495057;
    margin-bottom: 10px;
}

.card-body p {
    font-size: 16px;
    color: #495057;
}

/* Keyframes for Fade-In Up animation for cards */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Adjustments for responsiveness */
@media (max-width: 767px) {
    h1 {
        font-size: 28px;
        letter-spacing: 0.5px; /* Decrease letter spacing on small screens */
    }

    .secondary-text {
        font-size: 16px;
        animation: fadeInParagraph 1s forwards 1.5s; /* Delay and animate paragraph */
    }

    .card .icon {
        font-size: 30px;
    }

    .card-body p {
        font-size: 14px;
    }

    /* Stack h1 and p vertically for smaller screens */
    .container {
        display: flex;
        flex-direction: column; /* Stack vertically */
        align-items: center; /* Center horizontally */
    }

    /* Ensure the row is centered horizontally on smaller screens */
    .row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap; /* Ensure the cards wrap to the next line if necessary */
    }

    /* Ensure the cards are centered inside their columns */
    .col-lg-4,
    .col-md-6,
    .col-12 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 24px;
        letter-spacing: 0px; /* Further reduce letter-spacing for very small screens */
    }
    .yaziInfo{
        font-size: 15px;
    }
    .yazibaslik{
        font-size: 25px;
    }

    .secondary-text {
        font-size: 14px;
        animation: fadeInParagraph 1s forwards 1.5s; /* Delay and animate paragraph */
    }

    .card .icon {
        font-size: 25px;
    }

    .card-body p {
        font-size: 12px;
    }

    /* Stack h1 and p vertically on very small screens */
    .container {
        display: flex;
        flex-direction: column; /* Stack vertically */
        align-items: center; /* Center horizontally */
    }

    /* Ensure the row is centered on very small screens */
    .row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    /* Ensure the cards are centered inside their columns */
    .col-lg-4,
    .col-md-6,
    .col-12 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
