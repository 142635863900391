/* Genel sıfırlama */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 80px; /* Üstten biraz daha boşluk bırakıyoruz */
  width: 100%;
  background-color: transparent;
  position: absolute; /* Resmin üzerine yerleştirmek için absolute */
  top: 0; /* Üst kısmı resmin üstüne yerleştiriyoruz */
  left: 0;
  z-index: 1000; /* Navbar'ın her zaman resmin üstünde görünmesi için */
  margin-top: 0; /* Navbar'ı en üstte konumlandırıyoruz */
}

.navbar .logo {
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  z-index: 10;
  text-decoration: none;
  cursor: pointer;
}

.navbar .nav-links {
  display: flex;
  gap: 20px;
  z-index: 10;
}

.navbar .nav-links li {
  list-style: none;
}

.navbar .nav-links li:hover {
  opacity: 0.7;
  border-bottom: 1px solid white;
color: white;
}

.navbar .nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.navbar .toggler {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  z-index: 10;
}

.navbar .toggler-bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.navbar .toggler.active .toggler-bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.navbar .toggler.active .toggler-bar:nth-child(2) {
  opacity: 0;
}

.navbar .toggler.active .toggler-bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Toggle aktif olduğunda nav-links yukarıda sabit kalır */
.navbar .nav-links.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px; /* Navbar'ın hemen altına açılır */
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  margin-top: 20px;
  width: 100%;
  
  padding: 10px;
  border-radius: 10px;
}
.clickEfet a{
  font-family: sans-serif;
  font-size: 14px;
}

/* Hero Bölümü */
.hero {
  position: relative;
  height: 100vh;
  background: url('../images/ananavbar2.jpg') no-repeat center center/cover;
  padding: 0 50px;
  animation: heroFadeIn 2s forwards;
}

@keyframes heroFadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.hero .content {
  position: absolute;
  z-index: 2;
  color: #fff;
  text-align: left;
  bottom: 50px;
  left: 50px;
  max-width: 700px;
  opacity: 0; /* Başlangıçta görünmez */
  animation: heroContentAnimation 1.5s forwards; /* Hero içerik animasyonu */
  animation-delay: 1s; /* Geç başlaması için */
}

@keyframes heroContentAnimation {
  from {
      opacity: 0;
      transform: translateY(30px); /* Başlangıçta biraz aşağıda */
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.hero .content h1 {
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: bold;
}

.hero .content p {
  font-size: 20px;
  margin-bottom: 30px;
  max-width: 700px;
  line-height: 1.8;
}

.hero .content .btn {
  padding: 15px 25px;
  font-size: 18px;
  background-color: #ffc107;
  color: #000;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.hero .content .btn:hover {
  background-color: #ffa000;
}

/* Responsive Tasarım */
@media (max-width: 768px) {
  .navbar {
      padding: 10px 20px;
  }
  .sstLogo{
    margin: 0px;
    display: none;
  }

  .navbar .logo {
      font-size: 20px;
  }
  .navbar .nav-links li a {
    font-size: 14px !important;
  }

  .navbar .toggler {
      display: flex;
      justify-content: flex-end;
      align-items: center;
  }

  .navbar .nav-links {
      display: none;
  }

  .hero {
      height: 70vh; /* Küçük ekranlarda daha az yükseklik */
      padding: 0 20px;
  }

  .hero .content {
      bottom: 20px;
      left: 20px;
      right: 20px;
      max-width: none;
  }

  .hero .content h1 {
      font-size: 28px;
  }

  .hero .content p {
      font-size: 14px;
      margin-bottom: 20px;
  }

  .hero .content .btn {
      font-size: 14px;
      padding: 10px 20px;
      width: 100%;
  }
}
.clickEfet{
  cursor: pointer;
 
}
.logopng{
  width: 60px;
  height: 60px;
}
.logo{
  display: flex;
  align-items: center;
  justify-content: center;
}
.sstLogo{
  margin: 0px;
}