.hizmetler {
  margin-top: 50px;
  margin-bottom: 50px;
}

.row {
  display: flex;
  align-items: stretch; /* Yükseklik eşitlemesi */
}

/* Resim için stil */
.hizmetler-image {
  display: flex;
  justify-content: center; /* Resmin ortalanması */
  align-items: center; /* Resmin ortalanması */
}

.hizmetler-image img {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  width: 90%;
  height: 100%; /* Parent yüksekliği ile uyumlu */
}

/* Metin bölümü */
.title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #333;
}

.description {
  font-size: 1rem;
  margin-bottom: 15px;
  line-height: 1.6;
  color: #555;
  text-align: left; /* Yazının sola hizalanması */
}

/* Row içinde eşit yükseklik */
.align-items-stretch > .col-lg-5,
.align-items-stretch > .col-lg-7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Responsive düzen */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
    justify-content: center; /* Yatayda ortalamak için */
  }

  .hizmetler-image img {
    margin-bottom: 20px;
    height: auto; /* Yükseklik mobilde içeriğe göre ayarlanır */
  }

  .title {
    font-size: 1.5rem;
    text-align: center; /* Başlık mobilde ortalanacak */
  }

  .description {
    font-size: 0.9rem;
    text-align: center; /* Metin mobilde ortalanacak */
  }
}

