/* Genel Stil */
body {
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f8f9fa;
}

/* Konteyner */
.stats-container {
  max-width: 1320px;
  margin: 40px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Ana kart ve diğer kartlar arası boşluk */
}

/* Kart Tasarımı */
.stat-card {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 100%;
  min-width: 250px;  /* Minimum genişlik ekledik */
  max-width: 300px;  /* Maksimum genişlik */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.stat-value {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  transition: transform 0.5s ease;  /* Animasyon ekledik */
}

.stat-title {
  font-size: 18px;
  font-weight: 600;
  color: #555;
  margin-bottom: 8px;
}

.stat-description {
  font-size: 14px;
  color: #777;
}

/* Mobil Uyum */
@media (max-width: 992px) {
  .stat-card {
    margin-bottom: 20px; /* Ekran küçüldüğünde kartlar arasına boşluk */
  }
  .stats-container{
    margin: 20px auto;
    padding: 15px;
  }

  .stat-value {
    font-size: 30px;
  }

  .stat-title {
    font-size: 16px;
  }

  .stat-description {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .stat-card {
    margin-bottom: 15px;
  }
  .stats-container{
    margin: 10px auto;
    padding: 10px;
  }

  .stat-value {
    font-size: 26px;
  }

  .stat-title {
    font-size: 14px;
  }

  .stat-description {
    font-size: 12px;
  }
}
